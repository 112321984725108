@import url('https://fonts.googleapis.com/css2?family=Poetsen+One&display=swap');

html body {
  height: 100svh;
  max-height: 100svh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(246, 248, 252);
  color: #212121;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.firebase-emulator-warning {
  display: none;
}

.heightAndOverflow {
  height: 100% !important;
  overflow: auto !important;
}

.height-100vh {
  display: flex;
  flex-grow: 1;
  height: 100vh;
}

.homepage {
  font-family: 'Roboto';
  font-size: 4vmax;
  width: fit-content;
}

.max-Width-250 {
  max-width: 250px;
}

.stock-grid {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.container-Firm {
  display: flex;
  justify-content: center !important;
  align-items: center;
  min-height: 100vh;
  width: 100%;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.paper {
  max-width: 50rem;
  width: 100%;
}

.text-center {
  text-align: center;
}

.cicularSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.75);
}

.pointer {
  cursor: pointer !important;
}

.FirmImageLgScr {
  height: 58px !important;
  width: 140px !important;
}

.FirmImageSmScr {
  height: 33px !important;
  width: 80px !important;
}

.view-height-50 {
  min-height: auto;
  max-height: 50vh;
  overflow-y: auto;
}

.font-14size {
  font-size: '14px'
}

@media print {
  .no-print {
    display: none !important;
  }
}

.display-linebreak {
  white-space: pre-line;
}

.a4 {
  width: 210mm;
  /* A4 width in millimeters */
  height: 297mm;
  /* A4 height in millimeters */
  margin: 0 auto;
  display: block;
}

/* Responsive scaling */
@media (max-width: 100%) {
  .a4 {
    width: 100%;
    height: auto;
  }
}